import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import CartIcon from "@mui/icons-material/ShoppingCartOutlined";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Link from "next/link";
import { useState } from "react";
import { useCartContext, useWishlistContext } from "../contexts/CartContext";
import { InputAdornment, useMediaQuery, useTheme } from "@mui/material";
import Image from "next/image";
import { ENABLE_CARTS, ENABLE_WISHLISTS } from "../util/config";
import { SearchSubmitContainer } from "../containers/SearchSubmitContainer";
import { searchStyles } from "../style/searchStyles";
import { useRouter } from "next/router";
import { theme } from "../style/theme";

const pages = [];
if (ENABLE_CARTS) {
  pages.push({ text: "Carts", href: "/carts" });
}

pages.push({ text: "Team", href: "/team" });

if (ENABLE_WISHLISTS) {
  pages.push({ text: "Requisitions", href: "/carts/?wishlists=true" });
}

pages.push({ text: "Apps", href: process.env.NEXT_PUBLIC_BLOG_URL });

function Logo() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box width={"fit-content"}>
      <Link href={"/"}>
        <Image
          src={`https://bunsen-s3.s3.eu-west-2.amazonaws.com/public/${
            isMobile ? "logo192.png" : "Logo+horizontal+2.png"
          }`}
          alt="logo"
          height={isMobile ? 50 : 55}
          width={isMobile ? 50 : 116}
        />
      </Link>
    </Box>
  );
}

function SearchNavItem() {
  return (
    <Link href="/search" style={{ textDecoration: "none" }}>
      <IconButton variant="outlined" color="primary">
        <SearchIcon />
      </IconButton>
    </Link>
  );
}

function CartNavItem(props) {
  const cartContext = useCartContext();
  const wishlistContext = useWishlistContext();
  const { cartId } = ENABLE_CARTS ? cartContext : wishlistContext;
  const linkTo = cartId ? `/carts/${cartId}` : "/carts";

  return (
    <Link href={linkTo} style={{ textDecoration: "none" }}>
      <IconButton variant="outlined" color="primary" {...props}>
        <CartIcon />
      </IconButton>
    </Link>
  );
}

function SearchSubmit() {
  const router = useRouter();
  return (
    router.pathname !== "/search" &&
    router.pathname !== "/" && (
      <SearchSubmitContainer
        passThroughProps={{
          size: "small",
          sx: searchStyles,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          },
        }}
      />
    )
  );
}

function NavBarMobile() {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Logo />
      <SearchSubmit />
      <Box sx={{ flexGrow: 1 }} />
      <CartNavItem sx={{ mr: -1 }} />

      <IconButton
        size="large"
        aria-label="menu button"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
        sx={{ mr: -2 }}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        {pages.map((page, index) => (
          <Link
            href={page.href}
            key={index}
            // passHref
            style={{
              textDecoration: "none",
              color: theme.palette.text.primary,
            }}
          >
            <MenuItem onClick={handleCloseNavMenu}>{page.text}</MenuItem>
          </Link>
        ))}
      </Menu>
    </Box>
  );
}

function NavBarDesktop() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Logo />
      </Box>
      <SearchSubmit />
      {pages.map((page, index) => (
        <Button
          key={index}
          sx={{
            my: 2,
            color: "inherit",
            fontSize: "1rem",
            width: "auto",
            fontWeight: "regular",
          }}
          href={page.href}
          LinkComponent={Link}
        >
          {page.text}
        </Button>
      ))}
      <CartNavItem />
      <SearchNavItem />
    </Box>
  );
}

export function NavBar() {
  return (
    <AppBar
      position="fixed"
      // color="transparent"
      sx={{
        color: "black",
        backgroundColor: "white",
        borderBottom: "1px solid",
        borderColor: "divider",
        borderRadius: 0,
      }}
    >
      <Container>
        <Toolbar disableGutters>
          <Box
            sx={{
              display: {
                xs: "block",
                md: "none",
              },
              width: "100%",
            }}
          >
            <NavBarMobile />
          </Box>
          <Box
            sx={{
              display: {
                xs: "none",
                md: "block",
              },
              width: "100%",
            }}
          >
            <NavBarDesktop />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
