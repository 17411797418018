import { Box } from "@mui/material";
import * as Sentry from "@sentry/nextjs";
import Head from "next/head";
import { SWRConfig } from "swr";
import { AuthRedirect } from "../components/AuthRedirect";
import { NavBar } from "../components/Nav";
import { CartContextProvider } from "../contexts/CartContext";
import { OrgContextProvider } from "../contexts/OrgContext";
import { SearchContextProvider } from "../contexts/SearchContext";
import "../style/global.css";
import { CustomThemeProvider } from "../style/theme";
import { FullStory } from "../util/fullstory-script";
import Script from "next/script";
import { useEffect } from "react";

function defaultLayout(page) {
  const title = "Collaborative buying for teachers and schools.";
  const description =
    "Bunsen helps teachers and school finance departments work together to purchase school supplies, save time, and save money.";
  return (
    <>
      <Head>
        <title>{title}</title>
        <link rel="icon" type="image/x-icon" href="/favicon.ico"></link>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="/Screenshot_2022-12-01.png" />
      </Head>
      <Script
        type="text/javascript"
        src="https://app.termly.io/embed.min.js"
        data-auto-block="on"
        data-website-uuid="311b90f5-659b-4ef8-942a-ca0d7e1364da"
      ></Script>
      <Box sx={{ minHeight: 60, mb: 4 }}>
        <NavBar />
      </Box>
      {page}
    </>
  );
}

const unprotectedRoutes = [
  "/",
  "/login",
  "/signup",
  "/search",
  "/products.*",
  "/team",
  "/carts/.+", // note the "+" here, this is a regex
];

export default function MyApp({ Component, pageProps }) {
  // make layout configurable per page so we can make embedded versions of pages
  const getLayout = Component.getLayout || defaultLayout;

  // if we're not on the main domain, redirect to the main domain
  useEffect(() => {
    if (typeof window === "undefined") return;
    if (window.location.hostname === "localhost") return;
    if (!process.env.NEXT_PUBLIC_MAIN_HOSTNAME) return;
    if (window.location.hostname !== process.env.NEXT_PUBLIC_MAIN_HOSTNAME) {
      console.log(
        `redirecting to ${process.env.NEXT_PUBLIC_MAIN_HOSTNAME}${window.location.pathname}`
      );
      window.location.href = `https://${process.env.NEXT_PUBLIC_MAIN_HOSTNAME}${window.location.pathname}`;
    }
  }, []);

  return (
    <>
      <FullStory />
      <SWRConfig
        value={{
          onError: (error, key) => {
            if (
              error.status !== 403 &&
              error.status !== 401 &&
              error.status !== 404
            ) {
              console.error("SWR error: ", error.status, error, key);
              Sentry.captureException(error);
            }
          },
        }}
      >
        <CustomThemeProvider>
          <AuthRedirect
            unprotectedRoutes={unprotectedRoutes}
            redirectUrl={"/login"}
          >
            <OrgContextProvider>
              <CartContextProvider>
                <CartContextProvider isWishlist={true}>
                  <SearchContextProvider>
                    {getLayout(<Component {...pageProps} />)}
                  </SearchContextProvider>
                </CartContextProvider>
              </CartContextProvider>
            </OrgContextProvider>
          </AuthRedirect>
        </CustomThemeProvider>
      </SWRConfig>
    </>
  );
}
