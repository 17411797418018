import { fetcher } from "./fetcher";

export async function createCart(name, org, isWishlist = false) {
  // use fetch to call the api
  // return the cart
  const response = await fetcher("/api/cart", {
    method: "POST",
    body: JSON.stringify({ name, org, isWishlist }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
}

export async function updateCart(cartId, updates) {
  // use fetch to call the api
  // return the cart
  const response = await fetcher(`/api/cart/${cartId}/`, {
    method: "POST",
    body: JSON.stringify(updates),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
}

export async function addItemsToCart(cartId, items) {
  // use fetch to call the api
  // return the cart
  console.log("addItemsToCart", cartId, items);
  const response = await fetcher(`/api/cart/${cartId}/items`, {
    method: "POST",
    body: JSON.stringify({ items }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
}

export async function deleteCart(cartId) {
  // use fetch to call the api
  // return the response
  const response = await fetcher(`/api/cart/${cartId}/`, {
    method: "DELETE",
  });
  return response;
}
