import useSWR from "swr";
import { fetcher } from "../client/fetcher";
// import { useRouter } from "next/router";

export function useAuth() {
  // check login status by fetching the cart endpoint
  const { data, error } = useSWR("/api/cart", fetcher);
  const loading = !data && !error;
  const isLoggedIn = !!data && !error;
  const nonAuthError = error && error.status !== 401;

  // console.log("useAuth", { data, error, loading, isLoggedIn, nonAuthError });
  const handleLogin = () => {
    // set the current url as the redirect url
    const redirectUrl = encodeURIComponent(window.location.href);

    // send client to login app
    const loginUrl = process.env.NEXT_PUBLIC_LOGIN_URL;
    if (loginUrl) {
      window.location.href = `${loginUrl}?redirectUrl=${redirectUrl}`;
    }
  };

  return { error: nonAuthError, loading, isLoggedIn, handleLogin };
}
