export const ENABLE_CARTS =
  false || process.env.NEXT_PUBLIC_ENABLE_CARTS === "true";
export const ENABLE_WISHLISTS =
  true || process.env.NEXT_PUBLIC_ENABLE_WISHLISTS === "true";

// Cart status enum
export const CART_STATUS = {
  DRAFT: "Draft",
  AWAITING_APPROVAL: "Awaiting Approval",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  CANCELLED: "Cancelled",
  ORDERED: "Ordered",
  PARTIALLY_RECEIVED: "Partially Received",
  RECEIVED: "Received",
  CLOSED: "Closed",
};

// Ordinary users can only edit carts in these statuses
export const USER_CART_STATUSES = [CART_STATUS.DRAFT];

// Only admins can edit carts in these statuses
export const ADMIN_CART_STATUSES = [
  CART_STATUS.AWAITING_APPROVAL,
  CART_STATUS.REJECTED,
];
