import React from "react";
import { useRouter } from "next/router";
import { useAuth } from "../hooks/useAuth";

export const AuthRedirect = ({
  children,
  redirectUrl,
  protectedRoutes,
  unprotectedRoutes,
  defaultProtect = true,
}) => {
  const { isLoggedIn, loading } = useAuth();
  const router = useRouter();
  const { pathname } = router;
  const isProtectedRoute =
    protectedRoutes &&
    protectedRoutes.some((route) =>
      pathname.match(new RegExp("^" + route + "$"))
    );

  const isUnprotectedRoute =
    unprotectedRoutes &&
    unprotectedRoutes.some((route) =>
      pathname.match(new RegExp("^" + route + "$"))
    );
  const shouldProtect = defaultProtect ? !isUnprotectedRoute : isProtectedRoute;

  const isClientSide = typeof window !== "undefined";

  console.log(
    "AuthRedirect",
    shouldProtect,
    isLoggedIn,
    isProtectedRoute,
    isUnprotectedRoute
  );

  //

  if (!shouldProtect) return <>{children}</>;

  if (loading) return <div>loading...</div>;

  if (isLoggedIn) return <>{children}</>;

  isClientSide && router.push(redirectUrl);

  return null;
};
