export const searchStyles = {
    position: "fixed",
    top: { xs: 8, md: 16 },
    left: { xs: "50%", md: 180, lg: "50%" },
    zIndex: 9999,
    maxWidth: [200, 300, 400, 500],
    transform: {
      xs: "translate(-50%, 0)",
      md: "translate(0, 0)",
      lg: "translate(-50%, 0)",
    },
    "& fieldset": {
      borderRadius: "32px",
    },
  }