export const fetcher = (...args) => {
  let url = args[0];
  const options = args[1];
  const otherArgs = args.slice(2);
  // check if url contains host
  if (url.indexOf("http") === -1) {
    // if not, add host from env if it exists
    const host = process.env.NEXT_PUBLIC_API_HOST;
    url = host ? `${host}${url}` : url;
  }
  return fetch(url, { ...options, credentials: "include" }, ...otherArgs).then(
    async (res) => {
      // If the status code is not in the range 200-299,
      // we still try to parse and throw it.
      if (!res.ok) {
        const error = new Error("An error occurred while fetching the data.");
        // Attach extra info to the error object.
        try {
          error.info = await res.json();
        } catch (e) {
          error.info = "Could not parse error info";
        }
        error.status = res.status;
        throw error;
      }
      return res.json();
    }
  );
};
