import useSWR from "swr";
import { fetcher } from "../client/fetcher";

export function useCarts(wishlists) {
  const url = wishlists ? "/api/cart?wishlists=true" : "/api/cart";
  const { data, error, mutate } = useSWR(url, fetcher, {
    refreshInterval: 10000,
  });
  return { data: data?.carts, error, mutate };
}
