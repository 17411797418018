import { createContext, useContext, useEffect, useState } from "react";
import { useOrgs } from "../hooks/useOrgs";

export const OrgContext = createContext({ activeOrg: undefined });

export const OrgContextProvider = ({ children }) => {
  const { data: accessibleOrgs } = useOrgs();
  const [activeOrg, setActiveOrg] = useState(undefined);

  useEffect(() => {
    if (accessibleOrgs?.length > 0 && !activeOrg) {
      setActiveOrg(accessibleOrgs[0]);
    }
  }, [accessibleOrgs]);

  return (
    <OrgContext.Provider value={{ accessibleOrgs, activeOrg, setActiveOrg }}>
      {children}
    </OrgContext.Provider>
  );
};

export const useOrgContext = () => {
  return useContext(OrgContext);
};
