import { fetcher } from "./fetcher";

export async function createSearchLog(query, productId) {
  // use fetch to call the api
  // return the cart
  const response = await fetcher("/api/logs/prouct-search-click", {
    method: "POST",
    body: JSON.stringify({ query, productId }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
}
