import { Button } from "@mui/material";
import { useState } from "react";
import { SearchInput } from "../components/SearchInput";

// This Component is a container component that renders a form with a SearchInput component as its child
// It receives a button prop, which is a boolean value that determines whether or not to render a Button component as the endAdornment of the SearchInput component
// It also receives any additional props, which are passed to the SearchInput component
// it sets the the curernt search query by redirecting the user to the search page with the searchQuery value in the query string
export function SearchSubmitContainer({ button, passThroughProps }) {
  const [searchQuery, setSearchQuery] = useState("");
  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Define a function named handleSubmit, which prevents the default form submission behavior,
  // and redirects the user to the search page with the searchQuery value in the query string
  const handleSubmit = (event) => {
    event.preventDefault();
    window.location.href = `/search?q=${searchQuery}`;
  };

  // Return a form element with a SearchInput component as its child
  return (
    <form onSubmit={handleSubmit}>
      <SearchInput
        name="searchQuery"
        onChange={handleChange}
        // If button prop is true, add a Button component as the endAdornment of the SearchInput component
        InputProps={
          button && {
            disableUnderline: true,
            endAdornment: (
              <Button type="submit" variant="contained" onClick={handleSubmit}>
                Search
              </Button>
            ),
          }
        }
        {...passThroughProps}
      />
    </form>
  );
}
