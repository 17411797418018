import { createContext, useContext, useState } from "react";
import { createSearchLog } from "../client/logs";

export const SearchContext = createContext("");

export const SearchContextProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const logSearchResultClick = (product) => {
    console.log("logSearchResultClick", product);
    createSearchLog(searchQuery, product.id);
  };

  return (
    <SearchContext.Provider
      value={{ searchQuery, setSearchQuery, logSearchResultClick }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  return useContext(SearchContext);
};
