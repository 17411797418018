import React, { createContext, useContext, useEffect, useState } from "react";
import { useCarts } from "../hooks/useCarts";
import { useOrgContext } from "./OrgContext";
import { addItemsToCart, createCart } from "../client/carts";
import { useCart } from "../hooks/useCart";
import { ENABLE_CARTS } from "../util/config";

// note: loading the items in the cart is handled by the useCart hook
// this context is only for managing the cartId

const defaults = {
  cartId: null,
  carts: [],
  // eslint-disable-next-line no-unused-vars
  setCartId: (cartId) => {},
  cartData: null,
  error: null,
  mutate: null,
  addToCart: null,
};

const CartContext = createContext({ ...defaults });
const WishlistContext = createContext({ ...defaults });

export const CartContextProvider = ({ children, isWishlist }) => {
  const [cartId, setCartId] = useState();
  const {
    data: cartData,
    error,
    mutate,
    addToCart,
    localCart,
    clearLocalCart,
    isEditable,
  } = useCart(cartId);
  const { activeOrg } = useOrgContext();
  const { data: carts, mutate: mutateCarts } = useCarts(isWishlist);
  let convertLocalCartToServerCartRunning = false;

  // console.log("isWishlist", isWishlist, "cartId", cartId);
  // console.log("isWishlist", isWishlist, "carts", carts);

  const convertLocalCartToServerCart = async () => {
    // assume the user is logged in if carts data is available
    // when the user is logged in and a local cart exists,
    // create a new server cart, add the items from the local cart to the server cart,
    // and delete the local cart
    // set the cart id to the newly created server cart

    // dedupe calls to this function
    if (convertLocalCartToServerCartRunning) return;
    convertLocalCartToServerCartRunning = true;

    try {
      if (activeOrg && carts && localCart && localCart.items.length > 0) {
        // format date in localized format
        const dateString = new Date().toLocaleDateString();

        // local cart can only be converted to either a wishlist or a cart
        // so this is may only by done by the context that takes precedence
        const shouldConvert = isWishlist ? !ENABLE_CARTS : ENABLE_CARTS;
        if (!shouldConvert) return;

        const newCart = await createCart(
          `Requisition created ${dateString}`,
          activeOrg,
          isWishlist
        );
        await addItemsToCart(newCart.id, localCart.items);
        clearLocalCart();
        setCartId(newCart.id);
      }
    } finally {
      convertLocalCartToServerCartRunning = false;
    }
  };

  // call convertLocalCartToServerCart when carts data changes
  useEffect(() => {
    convertLocalCartToServerCart();
  }, [carts]);

  // set cartId to the first cart in the list if it is not set
  useEffect(() => {
    if (!cartId && carts && carts.length > 0) {
      // user is logged in and has carts but has not yet selected a cart
      setCartId(carts[0].id);
    }

    // if user is logged in but there are no carts, create a new one
    if (activeOrg && carts?.length === 0) {
      // note === 0, not !carts
      // console.log("creating new cart", activeOrg, isWishlist);
      createCart("my first requisition", activeOrg, isWishlist).then(() =>
        mutateCarts()
      );
    }
  }, [cartId, carts, activeOrg]);

  const Context = isWishlist ? WishlistContext : CartContext;

  return (
    <Context.Provider
      value={{
        cartId,
        carts,
        setCartId,
        cartData,
        error,
        mutate,
        addToCart,
        isEditable,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useCartContext = () => {
  return useContext(CartContext);
};

export const useWishlistContext = () => {
  return useContext(WishlistContext);
};
