import { TextField } from "@mui/material";

export function SearchInput({ searchQuery, onChange, ...props }) {
  // use MUI TextField
  return (
    <TextField
      value={searchQuery}
      onChange={onChange}
      placeholder="Search for school supplies"
      fullWidth
      // size="small"
      {...props}
    />
  );
}
