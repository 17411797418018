import useSWR from "swr";
import { fetcher } from "../client/fetcher";
import { addItemsToCart } from "../client/carts";
import { useEffect, useState } from "react";
import { USER_CART_STATUSES, ADMIN_CART_STATUSES } from "../util/config";

const loadCartFromLocalStorage = () => {
  // load cart from local storage if on client
  if (typeof window === "undefined") {
    return { items: [] };
  }
  let cart = localStorage.getItem("cart");
  if (!cart) {
    return { items: [] };
  }
  return JSON.parse(cart);
};

const saveCartToLocalStorage = (cart) => {
  localStorage.setItem("cart", JSON.stringify(cart));
};

export function useCart(id, userIsAdmin = false) {
  const [localCart, setLocalCart] = useState();
  const { data, error, mutate } = useSWR(id && `/api/cart/${id}`, fetcher, {
    refreshInterval: 30000,
  });

  const useLocalCart = !id;

  const cartStatus = data?.status;
  const isEditable =
    USER_CART_STATUSES.includes(cartStatus) ||
    useLocalCart ||
    (userIsAdmin && ADMIN_CART_STATUSES.includes(cartStatus));

  const clearLocalCart = () => {
    localStorage.removeItem("cart");
    setLocalCart({ items: [] });
  };

  useEffect(() => {
    if (!localCart) {
      setLocalCart(loadCartFromLocalStorage());
    }
  }, []);

  const addToServerCart = async (item, quantity) => {
    await addItemsToCart(id, [{ ...item, quantity }]);
    mutate();
  };

  const addToAnonymousCart = async (item, quantity) => {
    // console.log("adding item to cart", item, quantity);

    // check if item exists in cart
    let itemExists = false;
    localCart.items.forEach((cartItem) => {
      if (cartItem.id === item.id) {
        const currentQuantity = cartItem.quantity || 0;
        cartItem.quantity = currentQuantity + quantity;
        itemExists = true;
      }
    });

    // remove items from local cart if quantity is 0
    localCart.items = localCart.items.filter((item) => item.quantity > 0);

    // if item does not exist, add it
    if (!itemExists) {
      localCart.items.push({ ...item, quantity });
    }
    localCart.items = [...localCart.items];
    saveCartToLocalStorage(localCart);
    // console.log("setting localCart", localCart);
    setLocalCart({ ...localCart });
  };

  const addToCart = async (item, quantity) => {
    if (!isEditable) return;
    if (useLocalCart) return addToAnonymousCart(item, quantity);
    return addToServerCart(item, quantity);
  };

  const cartData = useLocalCart ? localCart : data;

  return {
    isEditable,
    data: cartData,
    error,
    mutate,
    addToCart,
    localCart,
    clearLocalCart,
  };
}
