import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material";
import { indigo } from "@mui/material/colors";

import { Cabin, Raleway } from "next/font/google";
const raleway = Raleway({ subsets: ["latin"] });
const cabin = Cabin({
  subsets: ["latin"],
  weight: ["400", "500", "600", "700"],
});

const FONT_PRIMARY = cabin.style.fontFamily;
const FONT_SECONDARY = raleway.style.fontFamily;

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#ff5722",
        light: "#fef3ea",
      },
      secondary: indigo,
      divider: "#e0e0e0",
    },
    typography: {
      fontFamily: FONT_PRIMARY,
      "h*": {
        fontFamily: FONT_SECONDARY,
      },
      h1: {
        // h1 is used for the page title, e.g. "Cart" on the cart page and the product name on the product page
        fontFamily: FONT_SECONDARY,
        fontWeight: "bold",
        fontSize: "24pt",
      },
      h2: {
        // h2 is used for the section title e.g Cart in the sidebar
        fontFamily: FONT_SECONDARY,
        fontWeight: "800",
        fontSize: "12pt",
        textTransform: "uppercase",
      },
      h3: {
        // h3 may be used in the future for sub-section titles
        fontFamily: FONT_SECONDARY,
        fontWeight: "500",
        fontSize: "14pt",
      },
      h4: {
        // h4 is used for text that occurs several times on a page e.g. the name of the product in the cart
        // it should stand out from other text to make it easier to scan the page
        fontFamily: FONT_PRIMARY,
        fontWeight: "bold",
        fontSize: "12pt",
        letterSpacing: 0.2,
      },
      h5: {
        // h5 is used for the product price in search results and the product price in the cart
        // this should also facilitate scanning
        fontFamily: FONT_PRIMARY,
        fontWeight: "bold",
        fontSize: "12pt",
      },
      h6: {
        fontFamily: FONT_SECONDARY,
        fontWeight: "300",
        marginBottom: "0em",
      },
      body1: {
        // body1 is used for the product description and other paragraphs
        // also used in for general text e.g. the quentity selector in the cart and text in tables.
        fontWeight: "300",
        fontSize: "12pt",
      },
      subtitle1: {
        // body2 is used for de-emphasized text e.g. vendor name in search results and cart.
        fontWeight: "300",
        fontSize: "12pt",
        color: "#777",
      },
    },
    components: {
      MuiStack: {
        defaultProps: {
          spacing: 2,
        },
      },
      MuiGrid: {
        defaultProps: {
          spacing: 2,
        },
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: "xl",
        },
      },
      MuiButton: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: {
            boxShadow: "none",
            textTransform: "none",
            fontWeight: "bold",
            letterSpacing: 0.5,
          },
        },
      },
      MuiCard: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: 10,
            border: "1px solid",
            borderColor: theme.palette.divider,
          }),
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: 10,
            border: "1px solid",
            borderColor: theme.palette.divider,
          }),
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: theme.palette.primary.light,
          }),
        },
        defaultProps: {
          elevation: 0,
        },
      },
    },
  })
);

export const CustomThemeProvider = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
